// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-find-your-happiness-js": () => import("./../../../src/pages/find-your-happiness.js" /* webpackChunkName: "component---src-pages-find-your-happiness-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-school-games-js": () => import("./../../../src/pages/school-games.js" /* webpackChunkName: "component---src-pages-school-games-js" */)
}

